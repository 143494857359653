@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
html,
body,
#root {
  height: 100%;
}

body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  background: white;
}

.appWrapper_menuBar__2JyzT {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 1px 16px 1px 16px;
  background: #323232;
}

.appWrapper_iconButton__oPwyB {
  height: 46px;
  width: 46px;
}

.appWrapper_iconButtonIcon__g_OoK {
  font-size: 24px !important;
}

.Login_loginBox__3jQxq {
  padding: 80px 0;
  text-align: center;
  height: 100%;
  background: wheat;
  position: fixed;
  width: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 40%,
    rgba(255, 255, 255, 0.8) 60%,
    rgba(255, 255, 255, 0.1) 100%
  );
}

.Login_loginBox__3jQxq h1 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 40px;
  transition-duration: 1s;
  transition-timing-function: ease-in-put;
  font-weight: 300;
  margin-top: 0;
}

.Login_loginInputWrapper__3v4-X {
  display: block;
  margin-top: 13px;
}

.Login_loginInput__3XBEy {
  width: 250px;
}

.switcher_switcher__1vz01 {
  padding: 0;
}

.switcher_buttonContainer__2gytH {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  padding: 10px;
  justify-content: center;
}

.switcher_iconButtonWrapper__3PUnD {
  margin: 0 35px 50px 35px;
  width: 100px;
}

.switcher_iconButton__3CsHz {
  height: 100px;
  width: 100px;
  border-radius: 23%;
  border: 0;
  background: #4ca1af;
  background: linear-gradient(to right, #c4e0e5, #4ca1af);
  -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5));
          filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5));
}

.switcher_iconButton__3CsHz:disabled {
  opacity: 50%;
}

.switcher_iconButtonLabel__2o3bd {
  text-align: center;
  display: block;
  font-weight: 300;
}

.switcher_flexRow__1Vhd7 {
  display: flex;
  justify-content: center;
}

.switcher_profilePicture__316eA {
  margin-top: 39px;
  border-radius: 130px;
  height: 130px;
  width: 130px;
}

.switcher_profileText__1k8dj {
  font-weight: 500;
  margin: 13px 0 0 0;
}

@media (max-width: 768px) {
  .switcher_buttonContainer__2gytH {
    grid-template-columns: auto auto;
  }
}

.accountSettings_accountSettingsButton__1IT7h {
  text-transform: none;
  color: #007aff !important;
  margin-bottom: 26px !important;
}

